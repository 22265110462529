import Util from "./util";

let  Util2 =
    {};
Util2.regionJsonConverter = {'330300':"温州市",
    '330301':"市辖区",
    '330302':"鹿城区",
    '330303':"龙湾区",
    '330304':"瓯海区",
    '330305':"洞头区",
    '330324':"永嘉县",
    '330326':"平阳县",
    '330327':"苍南县",
    '330328':"文成县",
    '330329':"泰顺县",
    '330371':"温州高新区(经开区)",
    '330381':"瑞安市",
    '330382':"乐清市",
    '330383':"龙港市",
    '330354':"生态园",
    '330355':"瓯江口",
};
Util2.projectTypeTransfer= {
    SPORT: "体育建筑",
    CBD: '综合建筑',
    HOSPITAL: '医疗卫生建筑',
    HOTEL: '宾馆饭店建筑',
    MARKET: '商场建筑',
    OFFICE: '办公建筑',
    TEACH: '文化教育建筑',
    OTHER: '其它建筑',
}
Util2.regionJson = {"市辖区":330301,"鹿城区":330302,"龙湾区":330303,"瓯海区":330304,"洞头区":330305,"永嘉县":330324,"平阳县":330326
    ,"苍南县":330327,"文成县":330328,"泰顺县":330329,"温州高新区(经开区)":330371,"瑞安市":330381,"乐清市":330382,"瓯江口":330355,"生态园":330354,"龙港市":330383};
export default Util2;
