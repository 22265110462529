import React, {useContext, useEffect, useState} from 'react';
import * as r from 'ramda';
import { Link, NavLink } from "react-router-dom";

import './nav.scss';
import Api from 'api';
import { Dropdown, Icon, Menu, Select, Spin } from 'antd';
import cookie from 'react-cookies';

import Img_Home from 'img/home.png';
import Img_Equipment from 'img/equipment.png';
import Img_Nenghao from 'img/nenghao.png';
import Img_Map from 'img/map.png';
import Img_Electricity from 'img/electricity.png';
import Img_Water from 'img/water.png';
import Img_R from 'img/r.png';
import Img_More from 'img/more.png';
import moment from 'moment';
import { isRoot,isRegion, moduleNavigation, setCurrentProject } from "src/context/NavItem";
import {context, firstAnalysisRoute, routeNames} from "src/router";

import $ from 'jquery';

const { Option } = Select

const logout = () => {
  cookie.remove('isLogin', { path: '/' });
  cookie.remove('projectId', { path: '/' });
  cookie.remove('projectName', { path: '/' });
  cookie.remove('token', { path: '/' });
  cookie.remove('uid', { path: '/' });
  cookie.remove('isRegion', { path: '/' });
  cookie.remove('region', { path: '/' });
  cookie.remove('regionProjectId', { path: '/' });
  cookie.remove('regionProjects', { path: '/' });
  sessionStorage.clear()
}
const regExp = /cn[\d\w\/]*/g;

const onSelect = (value, key) => {
    console.log('onSelect', key);
    setCurrentProject(value, key.props.children)
    // window.location.reload()
    console.log(window.location.href);
    console.log(window.location.hostname);
    console.log(window.location.pathname);

    let href =  window.location.href;
    if(href.indexOf('localhost')!=-1 || href.indexOf('3000')!=-1){
        window.location.href = 'http://localhost:3000/';
    }
    if(href.indexOf('wznhjc')!=-1){
        // console.log(url.replace(regExp,'cn/'));
        window.location.href = href.replace(regExp,'cn/');
    }

}

const menu = (
  <Menu>
      {isRegion()?'':
          <Menu.Item>
          <a href='https://admin.wznhjc.cn/'><Icon type="logout" />后台管理</a>
      </Menu.Item>}
    <Menu.Item onClick={logout}>
      <Link to='/Login'><Icon type="logout" />退出登录</Link>
    </Menu.Item>
  </Menu>
);
//直接在menu里判断region无法更新，但是下面的return是可以不断更新的，所以重写一份，在return里面判断
const menu2 = (
    <Menu>
        <Menu.Item onClick={logout}>
            <Link to='/Login'><Icon type="logout" />退出登录</Link>
        </Menu.Item>
    </Menu>
);

const navItems = modules => {
  const navMap = {
    dashboard: <li key='dashboard'>
      <NavLink activeClassName='active' to='/Index'><i><img src={Img_Home}
        alt="智慧云能源" /></i><span>首页</span></NavLink>
    </li>,
    devices: <li key='devices'>
      <NavLink activeClassName='active' to='/Equipment/ '><i><img src={Img_Equipment}
        alt="智慧云能源" /></i><span>设备管理</span></NavLink>
    </li>,
    devicesV2: <li key='devicesV2'>
      <NavLink activeClassName='active' to='/EquipmentV2/'><i><img src={Img_Equipment}
        alt="智慧云能源" /></i><span>设备管理V2</span></NavLink>
    </li>,
    analysis: <li key='analysis'>
      <NavLink activeClassName='active' to={firstAnalysisRoute(modules)}><i><img src={Img_Nenghao}
       alt="智慧云能源" /></i><span>综合分析</span></NavLink>
    </li>,
    map: <li key='map'>
      <NavLink activeClassName='active' to='/Map'><i><img src={Img_Map}
        alt="智慧云能源" /></i><span>能耗地图</span></NavLink>
    </li>,
    grid_monitor: <li key='grid_monitor'>
      <NavLink activeClassName='active' to='/Electrical'><i><img src={Img_Electricity}
        alt="智慧云能源" /></i><span>电网监测</span></NavLink>
    </li>,
    water_monitor: <li key='water_monitor'>
      <NavLink activeClassName='active' to='/Water/NetworkDiagram/1'><i><img src={Img_Water}
        alt="智慧云能源" /></i><span>水网监测</span></NavLink>
    </li>,
    statistics: <li key='statistics'>
      <NavLink activeClassName='active' to='/Report/IntervalReport'><i><img src={Img_Equipment}
        alt="智慧云能源" /></i><span>统计报表</span></NavLink>
    </li>,
    statisticsRegion: <li key='statisticsRegion'>
      <NavLink activeClassName='active' to='/ReportRegion/IntervalReportRegion'><i><img src={Img_Equipment}
        alt="智慧云能源" /></i><span>区域统计报表</span></NavLink>
    </li>,
      weatherDate: <li key='weatherDate'>
          <NavLink activeClassName='active' to='/WeatherDate/WeatherDateInterval'><i><img src={Img_Nenghao}
          alt="智慧云能源" /></i><span>天气数据统计</span></NavLink>
      </li>,
      average_building: <li key='average_building'>
          <NavLink activeClassName='active' to='/Average'><i><img src={Img_Nenghao}
          alt="智慧云能源" /></i><span>建筑能耗对比</span></NavLink>
      </li>,
      old_data: <li key='old_data'>
          <NavLink activeClassName='active' to='/OldData'><i><img src={Img_Nenghao}
          alt="智慧云能源" /></i><span>迁移数据</span></NavLink>
      </li>,
      noise: <li key='noise'>
          <NavLink activeClassName='active' to='/Noise'><i><img src={Img_Nenghao}
          alt="智慧云能源" /></i><span>噪声</span></NavLink>
      </li>,
      airdata: <li key='airdata'>
          <NavLink activeClassName='active' to='/AirData'><i><img src={Img_Nenghao}
           alt="智慧云能源" /></i><span>空气数据统计</span></NavLink>
      </li>,
      pvdata: <li key='pvdata'>
          <NavLink activeClassName='active' to='/PvData'><i><img src={Img_Nenghao}
           alt="智慧云能源" /></i><span>光伏数据统计</span></NavLink>
      </li>,
      sms: <li key='sms'>
          <NavLink activeClassName='active' to='/Sms'><i><img src={Img_Nenghao}
           alt="智慧云能源" /></i><span>短信</span></NavLink>
      </li>,
      statisAllStates: <li key='statisAllStates'>
          <NavLink activeClassName='active' to='/StatisAllStates'><i><img src={Img_Nenghao}
           alt="智慧云能源" /></i><span>项目状态统计</span></NavLink>
      </li>,
      drawingMonitor: <li key='drawingMonitor'>
          <NavLink activeClassName='active' to='/DrawingMonitor'><i><img src={Img_Nenghao}
           alt="智慧云能源" /></i><span>审图查看</span></NavLink>
      </li>,
    presentation: <li key='presentation'>
      <NavLink activeClassName='active' to='/ScreenVersionIndex'><i></i><span>投屏版</span></NavLink>
    </li>,
    regionDashboard: <li key='regionDashboard'>
      <NavLink activeClassName='active' to='/RegionDashboard'><i></i><span>区域首页</span></NavLink>
    </li>,
    regionPresentation: <li key='regionPresentation'>
      <NavLink activeClassName='active' to='/RegionPresentation'><i></i><span>区域投屏</span></NavLink>
    </li>,
      presentationV2: <li key='presentationV2'>
      <NavLink activeClassName='active' to='/PresentationV2'><i></i><span>投屏版V2</span></NavLink>
    </li>,
      carbonNeutral: <li key='carbonNeutral'>
      <NavLink activeClassName='active' to='/CarbonNeutral'><i></i><span>碳中和</span></NavLink>
    </li>,
    reportTemplates: <li key='reportTemplates'>
      <NavLink activeClassName='active' to='/ReportTemplates'><i></i><span>报告模板</span></NavLink>
    </li>,
    registerInfo: <li key='registerInfo'>
      <NavLink activeClassName='active' to='/RegisterInfo'><i></i><span>信息登记</span></NavLink>
    </li>,
    registerInfo2: <li key='registerInfo2'>
      <NavLink activeClassName='active' to='/RegisterInfo2'><i></i><span>信息登记</span></NavLink>
    </li>,
      registerRegion: <li key='registerRegion'>
      <NavLink activeClassName='active' to='/NewRegisterInfoAdminRegion'><i></i><span>注册查看</span></NavLink>
    </li>,
  }

  return isRoot() ? r.values(navMap) : r.values(r.omit(r.difference(r.keys(navMap), modules))(navMap))
}

var singleShow,hideShow;
// const hideArr=['Index','Equipment','Noise'];
const hideArr=[''];


const Nav = () => {
  const {state: {thisProject,projects,regionProjects}} = useContext(context)
  const nowTime = moment().format('HH:mm:ss');
  const [clock, setClock] = useState(nowTime);
  const [show, setShow] = useState(false);
  const [add, setAdd] = useState(false);
  const [navName, setNavName] = useState('');
  const [eventArr, setEventArr] = useState([]);
    // console.log('regionProjects------');
    // console.log(regionProjects);
    // console.log(r.groupBy(r.prop('projectId'))(regionProjects));


  useEffect(() => {
      // console.log('useEffect');
      // console.log('regionProjects------');
      // console.log(r.groupBy(r.prop('projectId'))(regionProjects));
    const time = setInterval(() => {
      setClock(nowTime)
    }, 1000);

    const navDiv = document.getElementById("navDiv");

      const activeDiv = $('.active');
      const hf = activeDiv.attr('href')?activeDiv.attr('href').trim().replace(new RegExp('/','g'),''):'';
      // console.log('hf----- '+ hf);
      if(navName != hf){
          setNavName(hf);
          //清空
          console.log('change the nav,remove the nav function ------- ');
          document.getElementById("nav").style.cssText="display:block";
          if(singleShow){
              console.log('remove1');
              navDiv.removeEventListener("mouseover", singleShow);
          }
          if(hideShow){
              console.log('remove2');
              navDiv.removeEventListener("mouseout", hideShow);
          }
          //添加
          if(hf && hideArr.indexOf(hf) !== -1 ){
              if(!add){
                  // console.log('toadd --');
                  // console.log('add1 --');
                  singleShow = ()=>{
                      document.getElementById("nav").style.cssText="display:block";
                  };
                  navDiv.addEventListener("mouseover", singleShow);
                  // console.log('add2 --');
                  hideShow = ()=>{
                      document.getElementById("nav").style.cssText="display:none";
                  };
                  navDiv.addEventListener("mouseout", hideShow);
              }
              setAdd(true);
          }

          setAdd(false);
          // console.log('add----'+add);
      };

    return function cleanup() {
      clearInterval(time);
    };


  })

  //nav 透明化处理
  //width:'100%',height:'60px',position:'absolute',zIndex:'999',backgroundColor:'#c8464100'
  return (
    <div id='navDiv' style={{height:'60px'}}>
      <nav className='nav' id="nav" >
        <ul>{
          r.isNil(thisProject) ? []
            : isRoot() ? navItems(r.keys(routeNames)) : navItems(thisProject.modules)
        }</ul>
        <div>
          <span className='clock'>{clock}</span>
          <img src={Img_R} className='userHead' alt="智慧云能源" />
            {/*如果是区域用户，直接隐藏对应的选项*/}
            <Dropdown overlay={isRegion()?menu2:menu} placement="bottomCenter">
                <div>
                    <span>{Api.projectName}</span>
                    <i><img src={Img_More} alt="智慧云能源" /></i>
                </div>
            </Dropdown>

        </div>
          {/*增加web页面的项目表格*/}
          <div className="user" style={{ padding: '10px' }}>
              {isRoot()||isRegion() ?<span style={{ Width: '10px', float: 'right', marginLeft: '20px', marginTop: '18px',  color: '#fafafa' }}>项目列表</span>:''}
              {isRoot() ? <Select
                  showSearch
                  style={{ minWidth: '200px', float: 'left', marginLeft: '20px', marginTop: '14px',background:'#6188e4',color:'#6188e4' }}
                  placeholder="Select a project"
                  optionFilterProp="children"
                  onSelect={onSelect}
                  defaultValue={Api.projectId}
                  filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
              >
                  {r.map(r.pick(['_id', 'title']))(r.flatten([projects])).map((k, i) => <Option value={k._id} key={i}>{(i+1)+k.title}</Option>)}
              </Select> :(isRegion()?<Select
                  showSearch
                  style={{ width:'200px',minWidth: '200px', float: 'left', marginLeft: '20px', marginTop: '14px',background:'#6188e4',color:'#6188e4' }}
                  placeholder="Select a project"
                  optionFilterProp="children"
                  onSelect={onSelect}
                  defaultValue={Api.projectId}
                  filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
              >
                      {regionProjects?r.map(r.pick(['projectId', 'projectName']))(r.flatten([regionProjects])).map((k, i) => <Option value={k.projectId}   key={i}>{(i+1)+k.projectName}</Option>):<Option value={1} key={1}>--</Option>}
                      {/*{regionProjects?r.map(r.pick(['projectId', 'projectName']))(r.flatten([regionProjects])).map((k, i) => <Option value={k.projectId} key={Math.random()}>{i}</Option>):<Option value={1} key={Math.random()}>--</Option>}*/}
              </Select>:'')}
          </div>

      </nav>



    </div>
  )
}


export default Nav;
