import React, {useContext} from 'react';
import * as r from 'ramda';
import './indexMidLeftForWeather.scss';
import ReactEcharts from 'echarts-for-react';
import Api from 'api';
import { timer } from 'rxjs';
import Border4 from '../../ui/border4/border4';
import Img_line from 'img/line.png';
import Img_bar from 'img/bar.png';
import {ColorContext} from "../../color";


const dateFormat = function (date, format) {
    date = new Date(date);
    var o = {
        'M+' : date.getMonth() + 1, //month
        'd+' : date.getDate(), //day
        'H+' : date.getHours(), //hour+8小时
        'm+' : date.getMinutes(), //minute
        's+' : date.getSeconds(), //second
        'q+' : Math.floor((date.getMonth() + 3) / 3), //quarter
        'S' : date.getMilliseconds() //millisecond
    };
    if (/(y+)/.test(format))
        format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));

    for (var k in o)
        if (new RegExp('(' + k + ')').test(format))
            format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));

    return format;
}


class IndexMidLeftForWeather extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            xAxisData: [],
            electricityData: [],
            airtimeData: [],
            airtimeData2: [],
            // 存储的顺序
            // `pm2d5`	0
            // 	`pm10`	1
            // 	`co2`	2
            // 	`tvoc`	3
            // 	`tvoc2`	4
            // 	`hcho`	5
            // 	`hcho2`	6
            // 	`temp`	7
            // 	`humi`	8
            // 	`addtime`	9
            airDataJson:{LanJu:[],QINDI:[]},
            pm2d5Data: [],
            millData: [],
            pm10Data: [],
            co2Data: [],
            tvocData: [],
            tvoc2Data: [],
            hchoData: [],
            hcho2Data: [],
            tempData: [],
            humiData: [],
            pm2d5Now: 0,
            pm10Now: 0,
            co2Now: 0,
            tvocNow: 0,
            tvoc2Now: 0,
            hchoNow: 0,
            hcho2Now: 0,
            tempNow: 0,
            humiNow: 0,

            notMerge: true,
            lazyUpdate: true,
            style: { width: "80%", height: "200px"},
            style2: { width: "50%", height: "200px",margin: '0',float: 'left' },
            style_temp: { fontSize:'30px',color:'#78bbfd',float:'left' },
            style_humi: { fontSize:'30px',color:'#d3fd8b',float:'left' },
            style_pm2d5: { fontSize:'30px',color:'#f19865',float:'left' },
            style_pm10: { fontSize:'30px',color:'#a6f16c',float:'left' },
            style_co2: { fontSize:'30px',color:'#56fddf',float:'left' },
            style_tvoc: { fontSize:'30px',color:'#7ab4ba',float:'left' },
            style_hcho: { fontSize:'30px',color:'#ba6190',float:'left' },
            active: "line"
        };
    }

    async getData(active='bar') {
        this.state = { getOption: { series: [] }, ...this.state }
        const data = new Date();
        const hh = data.getHours();

        const airData = await Api.postV3('/energyNew/airData', {
            project: Api.projectId,
            num:240
        });
        const airDataForNow = await Api.postV3('/energyForNew/weatherRightNow', {
            project: Api.projectId
        });

        // console.log('data-------------------:' + electricity.data.result.length);
        // console.log('dataRightNow-------------------:' + weatherForNow.data);
        let activityTime = [];
        let airtimeData = [];
        let airtimeData2 = [];
        let electricityData = [];
        let airDataJson={LanJu:[],QINDI:[]};
        let pm2d5Now= 0,
            pm10Now= 0,
            co2Now= 0,
            tvocNow= 0,
            tvoc2Now= 0,
            hchoNow= 0,
            hcho2Now= 0,
            tempNow= 0,
            humiNow= 0;

        let params = ['pm2d5','pm10','co2','tvoc','tvoc2','hcho','hcho2','temp','humi'];

        if (airData.data.result) {
            let res = airData.data.result;
            airtimeData = r.map(y => dateFormat(y,'MM-dd HH:mm'))(r.pluck('addtime')(res[1]));
            airtimeData2 = r.map(y => dateFormat(y,'MM-dd HH:mm'))(r.pluck('addtime')(res[0]));
            // airtimeData2 = (r.pluck('addtime')(res[0]));

            r.map(
                y =>
                {
                    console.log(y);
                    airDataJson.LanJu.push(r.pluck(y)(res[1]));
                    airDataJson.QINDI.push(r.pluck(y)(res[0]));
                }
            )(params);

            // r.map(
            //     y=>{
            //         airDataJson.LanJu.push(r.props(['pm2d5','pm10','co2','tvoc','tvoc2','hcho','hcho2','temp','humi'],y))
            //     }
            // )(res[1])
            // r.map(
            //     y=>{
            //         airDataJson.QINDI.push(r.props(['pm2d5','pm10','co2','tvoc','tvoc2','hcho','hcho2','temp','humi'],y))
            //     }
            // )(res[0])

            // console.log(airtimeData);
            // console.log(airtimeData2);
            // console.log(airDataJson);

        }

        // if (weatherForNow && weatherForNow.data) {
        //     let res = weatherForNow.data;
        //      tempNow= res.temp_value;
        //      humiNow= res.humi_value;
        //      dewNow= res.dew_value;
        //      atmosNow= res.atmos_value;
        //      radiaNow= res.radia_value;
        //      windspeedNow= res.windspeed_value;
        //      winddirNow= res.winddir_value;
        //
        // }

        this.setState({
            airtimeData:airtimeData,
            airtimeData2:airtimeData2,
            airDataJson:airDataJson

            // xAxisData: electricityTime,
            // electricityData:electricityData,
            // humiData:humiData,
            // dewData:dewData,
            // atmosData:atmosData,
            // radiaData:radiaData,
            // windspeedData:windspeedData,
            // winddirData:winddirData,
            // tempNow: tempNow,
            // humiNow: humiNow,
            // dewNow: dewNow,
            // atmosNow: atmosNow,
            // radiaNow: radiaNow,
            // windspeedNow: windspeedNow,
            // winddirNow: winddirNow,
            // active: active
        })
    }


    componentDidMount() {

        const upDataTime = 1 * 60 * 1000;//分钟
        const source = timer(0, upDataTime);
        source.subscribe((x) => { console.log('indexTopLeft:' + x); this.getData() })
        console.log('color ------');
        console.log(this.props.color);

    }

    selected(params) {

        if (params === "bar") {
            this.getData("bar")
        } else {
            this.getData("line")
        }
    }


    render() {
        const { xAxisData, active, electricityData, humiData, dewData, atmosData, radiaData, windspeedData, winddirData,winddirNow ,airDataJson,airtimeData,airtimeData2} = this.state;


        let getOption = {
            grid: { left: '55', top: '20', right: '30', bottom: '22' },
            // toolbox: {
            //     // y: 'bottom',
            //     feature: {
            //         magicType: {
            //             type: ['stack', 'tiled']
            //         },
            //         dataView: {},
            //         saveAsImage: {
            //             pixelRatio: 2
            //         }
            //     }
            // },
            legend: {
                textStyle: {
                    color: '#FEFEFF'
                },
                icon: 'circle'
            },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLabel: {
                    color: '#FEFEFF'
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: this.props.keyWord?airtimeData2:airtimeData,
            },
            yAxis: {
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                axisLabel: { color: '#FEFEFF' }
            },
            series: [{
                    type: 'line',
                    name: 'PM2.5',
                    // name: color,
                    data: this.props.keyWord?airDataJson.QINDI[0]:airDataJson.LanJu[0],
                    smooth: true,
                    itemStyle: {
                        normal: {
                            color: '#78bbfd'
                        }
                    },
                }]
        }

        let getOption2 = {
            grid: { left: '55', top: '20', right: '30', bottom: '22' },
            legend: {
                textStyle: {
                    color: '#FEFEFF'
                },
                icon: 'circle'
            },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLabel: {
                    color: '#FEFEFF'
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: this.props.keyWord?airtimeData2:airtimeData
            },
            yAxis: {
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                axisLabel: { color: '#FEFEFF' }
            },
            series: [{
                type: 'line',
                name: 'PM10',
                data: this.props.keyWord?airDataJson.QINDI[1]:airDataJson.LanJu[1],
                smooth: true,
                itemStyle: {
                    normal: {
                        color: '#d3fd8b'
                    }
                },
            }]
        }

        let getOption3 = {
            grid: { left: '55', top: '20', right: '30', bottom: '22' },
            // toolbox: {
            //     // y: 'bottom',
            //     feature: {
            //         magicType: {
            //             type: ['stack', 'tiled']
            //         },
            //         dataView: {},
            //         saveAsImage: {
            //             pixelRatio: 2
            //         }
            //     }
            // },
            legend: {
                textStyle: {
                    color: '#FEFEFF'
                },
                icon: 'circle'
            },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLabel: {
                    color: '#FEFEFF'
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: this.props.keyWord?airtimeData2:airtimeData
            },
            yAxis: {
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                axisLabel: { color: '#FEFEFF' }
            },
            series: [{
                type: 'line',
                name: '二氧化碳',
                data: this.props.keyWord?airDataJson.QINDI[2]:airDataJson.LanJu[2],
                smooth: true,
                itemStyle: {
                    normal: {
                        color: '#FD7F33'
                    }
                },
            }]
        }

        let getOption4 = {
            grid: { left: '55', top: '20', right: '30', bottom: '22' },

            legend: {
                textStyle: {
                    color: '#FEFEFF'
                },
                icon: 'circle'
            },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLabel: {
                    color: '#FEFEFF'
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: this.props.keyWord?airtimeData2:airtimeData
            },
            yAxis: {
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                axisLabel: { color: '#FEFEFF' }
            },
            series: [{
                type: 'line',
                name: 'TVOC',
                data: this.props.keyWord?airDataJson.QINDI[3]:airDataJson.LanJu[4],
                smooth: true,
                itemStyle: {
                    normal: {
                        color: '#fd8993'
                    }
                },
            }]
        }

        let getOption5 = {
            grid: { left: '55', top: '20', right: '30', bottom: '22' },

            legend: {
                textStyle: {
                    color: '#FEFEFF'
                },
                icon: 'circle'
            },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLabel: {
                    color: '#FEFEFF'
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: this.props.keyWord?airtimeData2:airtimeData
            },
            yAxis: {
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                axisLabel: { color: '#FEFEFF' }
            },
            series: [{
                type: 'line',
                name: '甲醛',
                data: this.props.keyWord?airDataJson.QINDI[5]:airDataJson.LanJu[6],
                smooth: true,
                itemStyle: {
                    normal: {
                        color: '#a6f16c'
                    }
                },
            }]
        }

        let getOption6 = {
            grid: { left: '55', top: '20', right: '30', bottom: '22' },
            // toolbox: {
            //     // y: 'bottom',
            //     feature: {
            //         magicType: {
            //             type: ['stack', 'tiled']
            //         },
            //         dataView: {},
            //         saveAsImage: {
            //             pixelRatio: 2
            //         }
            //     }
            // },
            legend: {
                textStyle: {
                    color: '#FEFEFF'
                },
                icon: 'circle'
            },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLabel: {
                    color: '#FEFEFF'
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: this.props.keyWord?airtimeData2:airtimeData
            },
            yAxis: {
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                axisLabel: { color: '#FEFEFF' }
            },
            series: [{
                type: 'line',
                name: '温度',
                data: this.props.keyWord?airDataJson.QINDI[7]:airDataJson.LanJu[7],
                smooth: true,
                itemStyle: {
                    normal: {
                        color: '#56fddf'
                    }
                },
            }]
        }

        let getOption7 = {
            grid: { left: '55', top: '20', right: '30', bottom: '22' },
            // toolbox: {
            //     // y: 'bottom',
            //     feature: {
            //         magicType: {
            //             type: ['stack', 'tiled']
            //         },
            //         dataView: {},
            //         saveAsImage: {
            //             pixelRatio: 2
            //         }
            //     }
            // },
            legend: {
                textStyle: {
                    color: '#FEFEFF'
                },
                icon: 'circle'
            },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLabel: {
                    color: '#FEFEFF'
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: this.props.keyWord?airtimeData2:airtimeData
            },
            yAxis: {
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                axisLabel: { color: '#FEFEFF' }
            },
            series: [{
                type: 'line',
                name: '湿度',
                data: this.props.keyWord?airDataJson.QINDI[8]:airDataJson.LanJu[8],
                smooth: true,
                itemStyle: {
                    normal: {
                        color: '#56fddf'
                    }
                },
            }]
        }






        // setInterval(function() {
        //     option.series[0].data[0].value = (Math.random() * 100).toFixed(2) - 0;
        //     myChart.setOption(option, true);
        // }, 2000);




            return (
            <div className='indexMidLeftForWeather'>
                <div className='indexMidLeftHeadForWeather'>
                    {/*<Border4 className='Angle' sideLength={'5px'}>*/}
                    {/*    <i className='round'></i>*/}
                    {/*</Border4>*/}
                    <span>天气数据</span>

                </div>
                    <div className='divEcharts' style={this.state.style2}>
                        <ReactEcharts option={getOption} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
                        {/*<span className='bigWord' style={this.state.style3}> {this.state.electricityData[this.state.electricityData.length-1]}℃ </span>*/}
                        {/*<span className='bigWord' style={this.state.style_pm2d5}> {this.state.tempNow}℃ </span>*/}
                    </div>
                    <div className='divEcharts' style={this.state.style2}>
                        <ReactEcharts option={getOption2} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
                        {/*<span className='bigWord' style={this.state.style3}> {this.state.humiData[this.state.humiData.length-1]}% </span>*/}
                        {/*<span className='bigWord' style={this.state.style_pm10}> {this.state.humiNow}% </span>*/}
                    </div>
                    <div className='divEcharts' style={this.state.style2}>
                        <ReactEcharts option={getOption3} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
                        {/*<span className='bigWord' style={this.state.style3}> {this.state.dewData[this.state.dewData.length-1]}Td </span>*/}
                        {/*<span className='bigWord' style={this.state.style_co2}> {this.state.dewNow}Td </span>*/}
                    </div>

                    <div className='divEcharts' style={this.state.style2}>
                        <ReactEcharts option={getOption4} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
                        {/*<span className='bigWord' style={this.state.style3}> {this.state.radiaData[this.state.radiaData.length-1]}Si </span>*/}
                        {/*<span className='bigWord' style={this.state.style_tvoc}> {this.state.radiaNow}Si </span>*/}
                    </div>
                <div className='divEcharts' style={this.state.style2}>
                        <ReactEcharts option={getOption5} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
                        {/*<span className='bigWord' style={this.state.style3}> {this.state.radiaData[this.state.radiaData.length-1]}Si </span>*/}
                        {/*<span className='bigWord' style={this.state.style_hcho}> {this.state.radiaNow}Si </span>*/}
                    </div>
                    <div className='divEcharts' style={this.state.style2}>
                        <ReactEcharts option={getOption6} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
                        {/*<span className='bigWord' style={this.state.style3}> {this.state.windspeedData[this.state.windspeedData.length-1]}m/s </span>*/}
                        {/*<span className='bigWord' style={this.state.style_temp}> {this.state.windspeedNow}m/s </span>*/}
                    </div>
                    <div className='divEcharts' style={this.state.style2}>
                        <ReactEcharts option={getOption7} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
                        {/*<span className='bigWord' style={this.state.style3}> {this.state.windspeedData[this.state.windspeedData.length-1]}m/s </span>*/}
                        {/*<span className='bigWord' style={this.state.style_humi}> {this.state.windspeedNow}m/s </span>*/}
                    </div>

                {/*<ReactEcharts option={getOption7} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />*/}
            </div>
        )
    }
}


export default IndexMidLeftForWeather;
